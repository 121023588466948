<template>
  <div>
    <video
        v-for="(video,i) of videosUrls"
        :key="i"
        v-show="video === videoSrc"
        :ref="`video${i}`"
        width="100%"
        preload="auto"
        @timeupdate="($event) => updateVideoTime($event,i)"
        @pause="videoPaused($event)"
        @play="($event) => videoPlaying($event)"
        controls
        :src="video"
        style="
          height: 420px;
          background-color: transparent;
          border-radius: 10px;
        "
        :currentTime="setTime"
    >

    </video>
  </div>
</template>

<script>

export default{
  props:{
    activeVideo:{
      type:String,
      default:'resource_path',
      validator:prop => ['resource_path','heatmap_path','gazeplot_path','fogmap_path']
    },
    videosUrls:{
      type:Object,
      required:true,
      default: () => ({
        resource_path: '',
        heatmap_path: '',
        gazeplot_path: '',
        fogmap_path: ''
      })
    },
    setTime:{
      type:Number,
      default:0,
    },
    isPlaying:{
      type:Boolean,
      default:false,
    }
    
  },
  data(){
    return {
      videoElement: null,
    }
  },
  watch:{
    setTime(newTime) {
      this.updateVideoCurrentTime(newTime);
    },
    
    videoSrc(){
      if(!this.videosUrls[this.activeVideo])
        return ""
      return this.videosUrls[this.activeVideo];
    }
  },
  computed:{
    videoSrc(){
      if(!this.videosUrls[this.activeVideo])
        return ""
      return this.videosUrls[this.activeVideo];
    }
  },
  methods:{
    videoPaused() {
      this.$emit("pause", false);
    },
    videoPlaying() {
      this.$emit("play", true);
    },

    updateVideoTime(event,refIndex) {
      this.$emit("videoTimeUpdate", this.$refs[`video${refIndex}`][0].currentTime);
    },
    
    updateVideoCurrentTime(newTime) {
      Object.keys(this.$refs).forEach(refName => {
        const videoElement = this.$refs[refName][0];
        if (videoElement) {
          videoElement.currentTime = newTime;

          if(videoElement.src===this.videoSrc && this.isPlaying){
            videoElement.play();
          }else{
            videoElement.pause();
          }
        }
      });
    },
  }

}
</script>