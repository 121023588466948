<template>
  <div>
    <!-- <div class="button-wrapper pl-5 mb-3">
      <button
        class="toggle-btn"
        @click="toggleEmotionMetrics"
        :class="{ active: showEmotionSeries }"
      >
        {{ $t("toggleEmotionMetrics") }}
      </button>
      <button
        class="toggle-btn"
        @click="toggleAttentionMetrics"
        :class="{ active: showAttentionSeries }"
      >
        {{ $t("toggleAttentionMetrics") }}
      </button>
    </div> -->
    <div ref="emotionGraph" style="width: 100%; height: 600px"></div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themesAnimated);

export default {
  props: {
    xyData: {
      type: Array,
      required: true,
    },
    currentTime: {
      type: Number,
    },
  },
  data() {
    return {
      attentionSeries: [],
      emotionSeries: [],
      showAttentionSeries: true,
      showEmotionSeries: true,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    currentTime(newTime) {
      if (this.currentTimeLine) {
        this.currentTimeLine.value = newTime;
      }
    },
  },
  methods: {
    init() {
      // Create chart instance
      const chart = am4core.create(this.$refs.emotionGraph, am4charts.XYChart);

      // Increase contrast by taking every second color
      chart.colors.step = 2;

      // Convert data timestamps from ms to seconds
      const convertedData = this.xyData.map((dataPoint) => {
        const newDataPoint = { ...dataPoint };
        newDataPoint.timestamp = newDataPoint.timestamp / 1000;
        return newDataPoint;
      });

      // Assign data
      chart.data = convertedData;

      // Create axes
      const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
      xAxis.title.text = "Timestamp (Seconds)";

      const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.title.text = "";

      // Set the exact range of the x-axis to match the video duration
      xAxis.min = 0; // Start at 0 seconds
      // xAxis.max = 6.5; // End at the exact duration of the video

      // Avoid rounding or extending the axis
      xAxis.strictMinMax = true;
      // xAxis.renderer.minGridDistance = 30;

      // Create series for each emotion
      // this.emotionSeries.push(
      //   this.createSeries(chart, "amusement", "Amusement")
      // );
      // this.emotionSeries.push(this.createSeries(chart, "anger", "Anger"));
      // this.emotionSeries.push(this.createSeries(chart, "awe", "Awe"));
      // this.emotionSeries.push(
      //   this.createSeries(chart, "contentment", "Contentment")
      // );
      // this.emotionSeries.push(this.createSeries(chart, "disgust", "Disgust"));
      // this.emotionSeries.push(
      //   this.createSeries(chart, "excitement", "Excitement")
      // );
      // this.emotionSeries.push(this.createSeries(chart, "fear", "Fear"));
      // this.emotionSeries.push(this.createSeries(chart, "sadness", "Sadness"));


      this.attentionSeries.push(
        this.createSeries(chart, "attention_score", "Attention")
      );
      this.attentionSeries.push(
        this.createSeries(chart, "clarity_score", "Clarity")
      );
      this.attentionSeries.push(
        this.createSeries(chart, "mental_demand", "Mental demand")
      );

      

      // this.createSeries(chart, "focus_score", "Focus");
      // this.createSeries(chart, "spread_score", "Spread");
      // this.createSeries(chart, "engagement", "Engagement");
      // this.createSeries(chart, "excitement", "Excitement");
      // this.createSeries(chart, "fear", "Fear");
      // this.createSeries(chart, "sadness", "Sadness");

      // Add legend
      chart.legend = new am4charts.Legend();   
      chart.legend.useDefaultMarker = true;

      // Customize legend markers
      const marker = chart.legend.markers.template;
      chart.legend.marginTop= 30;
      marker.width = 25; // Set marker width
      marker.height = 25; // Set marker height

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = "none"; // Ensure cursor doesn't zoom/pan

      // Add vertical line
      this.currentTimeLine = xAxis.axisRanges.create();
      this.currentTimeLine.grid.stroke = am4core.color("#FF0000");
      this.currentTimeLine.grid.strokeWidth = 2;
      this.currentTimeLine.grid.strokeOpacity = 0.6;
      this.currentTimeLine.label.inside = true;
      // this.currentTimeLine.label.text = "Current Time";
      this.currentTimeLine.label.fill = this.currentTimeLine.grid.stroke;
      this.currentTimeLine.label.verticalCenter = "bottom";
      this.currentTimeLine.label.dy = -10;
      this.currentTimeLine.value = this.currentTime;

      // Add event listener for cursor position change
      chart.cursor.events.on("cursorpositionchanged", this.updateCurrentTime);
      // Attach legend item click handler
      chart.legend.itemContainers.template.events.on("hit", (ev) => {
          const seriesName = ev.target.dataItem.dataContext.name;
          this.onLegendItemClick(ev, seriesName);
        });
      this.chart = chart; // Save reference to chart
    },
    getColorMap() {
      return {
        Amusement: am4core.color("#ff66a2"),
        Anger: am4core.color("#7c7c7c"),
        Awe: am4core.color("#ffda40"),
        Contentment: am4core.color("#00fa9a"),
        Disgust: am4core.color("#a974bc"),
        Excitement: am4core.color("#ff4040"),
        Fear: am4core.color("#a9dd4d"),
        Sadness: am4core.color("#59b0ff"),
      };
    },

    // Create series
    createSeries(chart, field, name) {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueX = "timestamp";
      series.dataFields.valueY = field;
      series.name = name;
      series.strokeWidth = 2;
      series.tensionX = 0.8;
      // series.bullets.push(new am4charts.CircleBullet());
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.legendSettings.valueText = "{valueY}";
      const colorMap = this.getColorMap();
      if (colorMap[name]) {
        series.stroke = colorMap[name];
        series.fill = colorMap[name];
      }
      return series;
    },

    onLegendItemClick(event, seriesName) {
      // Find the matching series by name in both emotion and attention series arrays
      this.showEmotionSeries = false;
      this.showAttentionSeries = false;
    },

    updateCurrentTime(event) {
      const xAxis = this.chart.xAxes.getIndex(0);
      const cursorX = this.chart.cursor.xPosition;
      const timestampInSeconds = xAxis.positionToValue(cursorX);
      // const timestampInMs = timestampInSeconds * 1000;
      this.$emit("updateCurrentTime", timestampInSeconds);
    },
    toggleAttentionMetrics() {
      this.attentionSeries.forEach((series) => {
        if (!this.showAttentionSeries) {
          series.show();
        } else {
          series.hide();
        }
      });
      this.showAttentionSeries = !this.showAttentionSeries;
    },

    toggleEmotionMetrics() {
      this.emotionSeries.forEach((series) => {
        if (!this.showEmotionSeries) {
          series.show();
        } else {
          series.hide();
        }
      });
      this.showEmotionSeries = !this.showEmotionSeries;
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scoped>
.button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.toggle-btn {
  border-radius: 26px;
  height: 50px;
  width: 180px;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #cbd2e0;
  border-color: #cbd2e0;
}

.active {
  background-color: var(--emotiva-pink);
  color: #fff;
  transition: 0.3s;
}

#chartdiv {
  width: 100%;
  height: 500px;
}
</style>
